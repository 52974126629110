import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Checkbox, notification, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const ClassAttendanceUpdate = () => {
  const updateSrudentsRedux = useSelector(
    (state) => state?.timetableSlice?.record
  );
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const academicYearId = localStorage.getItem("academicYearId");
  const navigate = useNavigate();
  const location = useLocation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const selectedDate = location.state?.selectedDate;

  useEffect(() => {
    console.log(updateSrudentsRedux, "checking inside component");
    if (updateSrudentsRedux?.gradeId && updateSrudentsRedux?.sectionId) {
      fetchStudentData(
        updateSrudentsRedux.gradeId,
        updateSrudentsRedux.sectionId
      );
    }

    const attendanceDate = moment(selectedDate || updateSrudentsRedux?.date);
    const currentDate = moment();
    const hoursDifference = currentDate.diff(attendanceDate, "hours");
    if (hoursDifference > 72) {
      setIsButtonDisabled(true);
    }
  }, [updateSrudentsRedux, selectedDate]);

  const fetchStudentData = async (gradeId, sectionId) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/get-student-by-grade-section?gradeId=${gradeId}&sectionId=${sectionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setStudents(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching student data:", error.message || error);
      notification.error({ message: "Error fetching student data" });
      setLoading(false);
    }
  };

  const handleCheckboxChange = (studentId) => {
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student._id === studentId
          ? { ...student, checked: !student.checked }
          : student
      )
    );
  };

  const updateAttendance = async () => {
    if (isButtonDisabled) {
      notification.error({
        message: "Attendance update disabled after 72 hours",
      });
      return;
    }
    setLoading(true);
    try {
      const payload = {
        teacherId: updateSrudentsRedux?.teacher[0]?._id,
        assistantTeachers: [],
        students: students
          .filter((student) => student.checked)
          .map((student) => ({ studentId: student._id })),
        date: selectedDate || new Date(), // Use selecte
        startTime: updateSrudentsRedux.startTime,
        endTime: updateSrudentsRedux.endTime,
        subjectId: updateSrudentsRedux.subjectId,
        academicYearId: academicYearId, // Hardcoded academic year ID for testing
        gradeId: updateSrudentsRedux.gradeId,
        sectionId: updateSrudentsRedux.sectionId,
      };
      console.log("Payload being sent:", payload);

      const attendanceId = updateSrudentsRedux._id; // Replace with the actual attendance ID if available
      const url = attendanceId
        ? `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/attendance/class-attendance/${attendanceId}`
        : `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/attendance/class-attendance`;

      const method = attendanceId ? "put" : "post";
      const res = await axios[method](url, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      notification.success({
        message: attendanceId
          ? "Attendance updated successfully"
          : "Attendance marked successfully",
      });
      navigate(-1);
    } catch (error) {
      console.error("Error updating attendance:", error.message || error);
      notification.error({ message: "Error updating attendance" });
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  const columns = [
    { title: "Student Name", dataIndex: "studentName", key: "studentName" },

    { title: "Attendance", dataIndex: "attendance", key: "attendance" },
  ];

  const data = students.map((student) => {
    const isChecked = updateSrudentsRedux.students.some(
      (s) => s.studentId._id === student._id && s.tag
    );
    return {
      key: student._id,
      studentName: `${student.firstName} ${student.lastName}`,
      itsNo: student.itsNo,
      attendance: (
        <Checkbox
          checked={student.checked || isChecked}
          onChange={() => handleCheckboxChange(student._id)}
        />
      ),
    };
  });

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>Class Attendance Update</h2>
        <button onClick={handleBackClick} className="btn btn-primary">
          Back
        </button>
      </div>
      {loading && (
        <div className="spinner-container">
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            strokeColor="#506EE4"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      <Table columns={columns} dataSource={data} loading={loading} />
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Tooltip
          title={
            isButtonDisabled
              ? "Update disabled after 72 hours"
              : "Update Attendance"
          }
        >
          <button
            onClick={updateAttendance}
            className="btn btn-primary"
            // disabled={isButtonDisabled}
          >
            Update Attendance
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ClassAttendanceUpdate;
