import React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ allowedRoles }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const jwtToken = localStorage.getItem("accessToken");
  const userType = localStorage.getItem("user");
  const academicYearId = localStorage.getItem("academicYearId");

  // Redirect to login if no JWT token
  if (!jwtToken) {
    return <Navigate to="/" replace />;
  }

  // Check if user's role is allowed
  if (allowedRoles && !allowedRoles.includes(userType)) {
    return <Navigate to="/" replace />;
  }

  // Show modal if academicYearId is missing and not on AcademicAdd page
  if (
    !academicYearId &&
    userType === "school-admin" &&
    location.pathname !== "/school/AcademicAdd"
  ) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            background: "white",
            padding: "2rem",
            borderRadius: "8px",
            textAlign: "center",
            maxWidth: "500px",
          }}
        >
          <h2>Academic Year Required</h2>
          <p style={{ padding: "1rem 0" }}>
            You need to set an academic year to start creating your school data.
          </p>
          <button
            onClick={() =>
              navigate("/school/AcademicAdd", {
                state: {
                  message:
                    "Please create an Academic year to start entering your school data.",
                },
              })
            }
            style={{
              padding: "0.5rem 1rem",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginTop: "1rem",
            }}
          >
            Create Academic Year
          </button>
        </div>
      </div>
    );
  }

  // Render the child routes if all checks pass
  return <Outlet />;
};

export default ProtectedRoute;
