import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { TimePicker } from "antd";
import { Button, Table, Space } from "antd";
import {
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
} from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import { notification } from "antd";
import { RotatingLines } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as XLSX from "xlsx"; // Add XLSX for exporting to Excel
import { saveAs } from "file-saver";

function WorkingDays() {
  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const academicYearId = localStorage.getItem("academicYearId");
  const academic_Year = location?.state?.academicYear;
  const [couponsList, setCouponsList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collecIds, setCollecIds] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [teacherType, setTeacherType] = useState("");
  const [userType, setUserType] = useState("");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [filterStage, setFilterStage] = useState("");
  const [filterGrade, setFilterGrade] = useState("");
  const [filterSection, setFilterSection] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [dateRange, setdateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [totalDoc, setTotalDoc] = useState();
  const navigate = useNavigate();
  const [uncheckedHoliday, setUncheckedHoliday] = useState(false);
  const [specific, setSpecific] = useState(false);
  const [data, setData] = useState([
    { day: "Monday", startTime: "", endTime: "", selected: false },
    { day: "Tuesday", startTime: "", endTime: "", selected: false },
    { day: "Wednesday", startTime: "", endTime: "", selected: false },
    { day: "Thursday", startTime: "", endTime: "", selected: false },
    { day: "Friday", startTime: "", endTime: "", selected: false },
    { day: "Saturday", startTime: "", endTime: "", selected: false },
    { day: "Sunday", startTime: "", endTime: "", selected: false },
  ]);

  const [additionalPeriods, setAdditionalPeriods] = useState({});

  const handleUncheckedHolidayChange = () => {
    setUncheckedHoliday(!uncheckedHoliday);
    const newData = data.map((item) =>
      item.day === "Sunday"
        ? { ...item, selected: false }
        : { ...item, selected: true }
    );
    setData(newData);
  };

  const handleSpecificChange = () => {
    setSpecific(!specific);
    if (!specific) {
      const newData = data.map((item) => ({ ...item, selected: false }));
      setData(newData);
    }
  };

  const handleSelect = (id) => {
    const newData = data.map((item) =>
      item.day === id ? { ...item, selected: !item.selected } : item
    );
    setData(newData);
  };

  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setData(data.map((item) => ({ ...item, selected: newSelectAll })));
  };

  const handleStartTimeChange = (time, value, day) => {
    const newData = data.map((item) =>
      item.day === day
        ? { ...item, startTime: value, startTimevalue: time }
        : item
    );
    setData(newData);
  };

  const handleEndTimeChange = (time, value, day) => {
    const newData = data.map((item) =>
      item.day === day ? { ...item, endTime: value, endTimevalue: time } : item
    );
    setData(newData);
  };

  const handleAddPeriod = (day) => {
    setAdditionalPeriods((prev) => ({
      ...prev,
      [day]: [...(prev[day] || []), { title: "", startTime: "", endTime: "" }],
    }));
  };

  const handleAdditionalPeriodChange = (day, index, type, value) => {
    setAdditionalPeriods((prev) => {
      const updatedPeriods = [...(prev[day] || [])];
      updatedPeriods[index][type] = value;
      return { ...prev, [day]: updatedPeriods };
    });
  };

  const handleDeletePeriod = (day, index) => {
    setAdditionalPeriods((prev) => {
      const updatedPeriods = [...(prev[day] || [])];
      updatedPeriods.splice(index, 1);
      return { ...prev, [day]: updatedPeriods };
    });
  };

  const columns = [
    {
      title: <Checkbox checked={selectAll} onChange={toggleSelectAll} />,
      dataIndex: "selected",
      render: (_, record) => (
        <Checkbox
          checked={record?.selected}
          onChange={() => handleSelect(record?.day)}
        />
      ),
      width: 20,
    },
    {
      title: "Day",
      dataIndex: "day",
    },
    {
      title: "Start Time",
      render: (_, record) => (
        <TimePicker
          onChange={(time, value) => {
            handleStartTimeChange(time, value, record.day);
          }}
          showNow={false}
          disabled={!record.selected}
          placeholder="Select Start Time"
          value={record.startTimevalue || null}
          format="HH:mm"
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "End Time",
      render: (_, record) => (
        <TimePicker
          onChange={(time, value) => {
            handleEndTimeChange(time, value, record.day);
          }}
          showNow={false}
          disabled={!record.selected}
          placeholder="Select End Time"
          value={record.endTimevalue}
          format="HH:mm"
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Recess",
      render: (_, record) => (
        <div>
          {(additionalPeriods[record.day] || []).map((period, index) => (
            <div key={index} className="d-flex gap-2 mb-2 align-items-center">
              <input
                type="text"
                placeholder="Title"
                value={period.title}
                onChange={(e) =>
                  handleAdditionalPeriodChange(
                    record.day,
                    index,
                    "title",
                    e.target.value
                  )
                }
                style={{ width: "20%" }}
              />
              <TimePicker
                onChange={(time, value) => {
                  if (
                    dayjs(value, "HH:mm").isBefore(
                      dayjs(record.startTime, "HH:mm")
                    ) ||
                    dayjs(value, "HH:mm").isAfter(
                      dayjs(record.endTime, "HH:mm")
                    )
                  ) {
                    notification.error({
                      message: "Validation Error",
                      description:
                        "Period time must be within the school start and end times.",
                    });
                    return;
                  }
                  handleAdditionalPeriodChange(
                    record.day,
                    index,
                    "startTime",
                    value
                  );
                }}
                placeholder="Start Time"
                value={
                  period.startTime ? dayjs(period.startTime, "HH:mm") : null
                }
                format="HH:mm"
                style={{ width: "20%" }}
              />
              <TimePicker
                onChange={(time, value) => {
                  if (
                    dayjs(value, "HH:mm").isBefore(
                      dayjs(record.startTime, "HH:mm")
                    ) ||
                    dayjs(value, "HH:mm").isAfter(
                      dayjs(record.endTime, "HH:mm")
                    )
                  ) {
                    notification.error({
                      message: "Validation Error",
                      description:
                        "Period time must be within the school start and end times.",
                    });
                    return;
                  }
                  handleAdditionalPeriodChange(
                    record.day,
                    index,
                    "endTime",
                    value
                  );
                }}
                placeholder="End Time"
                value={period.endTime ? dayjs(period.endTime, "HH:mm") : null}
                format="HH:mm"
                style={{ width: "20%" }}
              />
              <Button onClick={() => handleDeletePeriod(record.day, index)}>
                Delete
              </Button>
            </div>
          ))}
          <Button onClick={() => handleAddPeriod(record.day)}>Add</Button>
        </div>
      ),
    },
  ];

  const filteredWeekdays = data
    .filter((item) => item.selected && item.startTime && item.endTime)
    .map((item) => ({
      day: item.day,
      start_time: item.startTime,
      end_time: item.endTime,
      additionals: (additionalPeriods[item.day] || []).map((period) => ({
        title: period.title,
        start_time: period.startTime,
        end_time: period.endTime,
      })),
    }));

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    // Check if any selected day is missing start or end time
    const invalidDays = data.filter(
      (item) => item.selected && (!item.startTime || !item.endTime)
    );

    if (
      form.checkValidity() === false ||
      !startDate ||
      !endDate ||
      filteredWeekdays.length === 0 ||
      invalidDays.length > 0
    ) {
      if (!startDate || !endDate) {
        notification.error({
          message: "Validation Error",
          description: "Please select a valid date range.",
        });
        return false;
      }

      if (filteredWeekdays.length === 0) {
        notification.error({
          message: "Validation Error",
          description:
            "Please provide valid start and end times for at least one selected day.",
        });
        return false;
      }

      if (invalidDays.length > 0) {
        notification.error({
          message: "Validation Error",
          description:
            "Please enter start and end times for all selected days.",
        });
        return false;
      }
    } else {
      setVisible(true);
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    // Check if any selected day is missing start or end time
    const invalidDays = data.filter(
      (item) => item.selected && (!item.startTime || !item.endTime)
    );

    if (
      form.checkValidity() === false ||
      !startDate ||
      !endDate ||
      filteredWeekdays.length === 0 ||
      invalidDays.length > 0
    ) {
      if (!startDate || !endDate) {
        notification.error({
          message: "Validation Error",
          description: "Please select a valid date range.",
        });
        return false;
      }

      if (filteredWeekdays.length === 0) {
        notification.error({
          message: "Validation Error",
          description:
            "Please provide valid start and end times for at least one selected day.",
        });
        return false;
      }

      if (invalidDays.length > 0) {
        notification.error({
          message: "Validation Error",
          description:
            "Please enter start and end times for all selected days.",
        });
        return false;
      }
    } else {
      setVisible(true);
    }
  };

  const handleSubmitsend = async (e) => {
    e.preventDefault();

    setVisible(false); // Hide any visible modal or state during the submission

    try {
      // Prepare the request payload
      const payload = {
        school_type_id: filterSection,
        academicYearId: academic_Year,
        stageId: filterStage, // Include selected stage ID
        start_date: new Date(startDate).toISOString(), // Convert to ISO string
        end_date: new Date(endDate).toISOString(), // Convert to ISO string
        weekdays: filteredWeekdays, // Use the transformed array
      };

      // Log the payload to check its content
      console.log("Payload:", payload);

      // Make the API request
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/working-day`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful submission
      notification.success({
        message: "Success",
        description: "Working-Day Added Successfully",
        duration: 1.5,
      });

      // Navigate to the desired route
      navigate("/school/working-days-listing");

      // Reset form data or other states
      // resetData();
    } catch (error) {
      // Improved error handling
      console.error("Error submitting form:", error);

      notification.error({
        message: "Submission Error",
        description:
          error.response?.data?.message ||
          "Something went wrong while adding the working-day!",
      });

      // Optionally, show modal or enable form again
      setVisible(true);
    }
  };

  console.log("data is ere", data);
  const [allStages, setAllStages] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [allSections, setAllSections] = useState([]);

  const getSections = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/school-type`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllSections(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStages = async () => {
    // New function to fetch stages
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/stage-grade-section/stage`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllStages(res?.data?.records);
      console.log("checking all stages", res?.data?.records);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSections();
    getStages(); // Fetch stages when component mounts
  }, []);

  const [requestData, setRequestData] = useState({
    is_issued: true,
    remark: "",
    userId: "",
  });

  return (
    <div>
      <CContainer fluid>
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="StaticBackdropExampleLabel"
        >
          <CModalHeader>
            <CModalTitle id="StaticBackdropExampleLabel">
              Form Submission
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            Are you sure you wish to submit this form?
          </CModalBody>
          <CModalFooter className="pl-0">
            <button
              className="btn btn-dark ms-0 me-3"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <CButton color="primary" onClick={handleSubmitsend}>
              Yes!
            </CButton>
          </CModalFooter>
        </CModal>

        <div className="mb-2">
          <div className="d-flex justify-content-between newmwnu mb-2">
            <h2 className="mb-2">Add Working Day And Timing</h2>
            <div className="d-flex gap-2  Assign_section mb-1">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="btn btn-primary"
              >
                Back
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="filters d-flex gap-3 pb-2 align-items-center flex-wrap Teacher_Leave_mobile space_div">
              <div className="d-flex align-items-center pl-2 gap-3 ">
                <select
                  value={filterSection}
                  onChange={(e) => setFilterSection(e.target.value)}
                  className="form-control form-select"
                  style={{ width: "200px" }}
                >
                  <option value="">Select School Type</option>
                  {allSections?.map((res, i) => (
                    <option value={res?._id} key={i}>
                      {res?.type}{" "}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex align-items-center pl-2 gap-3 ">
                <select
                  value={filterStage}
                  onChange={(e) => setFilterStage(e.target.value)}
                  className="form-control form-select"
                  style={{ width: "200px" }}
                >
                  <option value="">Select Stage</option>
                  {allStages?.map((stage) => (
                    <option key={stage._id} value={stage._id}>
                      {stage.stage}
                    </option>
                  ))}
                </select>
              </div>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setdateRange(update);
                }}
                isClearable={false}
                placeholderText="Select Date Range"
                dateFormat="dd-MM-yyyy"
              />
              <button
                type="button"
                onClick={() => {
                  setdateRange([null, null]);
                  setData([
                    {
                      day: "Monday",
                      startTime: "",
                      endTime: "",
                      selected: false,
                    },
                    {
                      day: "Tuesday",
                      startTime: "",
                      endTime: "",
                      selected: false,
                    },
                    {
                      day: "Wednesday",
                      startTime: "",
                      endTime: "",
                      selected: false,
                    },
                    {
                      day: "Thursday",
                      startTime: "",
                      endTime: "",
                      selected: false,
                    },
                    {
                      day: "Friday",
                      startTime: "",
                      endTime: "",
                      selected: false,
                    },
                    {
                      day: "Saturday",
                      startTime: "",
                      endTime: "",
                      selected: false,
                    },
                    {
                      day: "Sunday",
                      startTime: "",
                      endTime: "",
                      selected: false,
                    },
                  ]);
                }}
                className="btn btn-primary"
              >
                Reset Filter
              </button>
            </div>
          </div>
        </div>

        {loading && (
          <div className="spinner-container">
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              strokeColor="#506EE4"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
        <div className="max-width-500px"></div>
        <Table
          rowKey="_id"
          columns={columns}
          className="bordered-table"
          dataSource={data}
          pagination={false}
        />

        <div className="d-flex justify-content-center mt-5">
          <button className="btn btn-dark" type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </CContainer>
    </div>
  );
}

export default WorkingDays;
