// src/redux/timeTableSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startTime: null,
  endTime: null,
  day: null,
  record: null, // Add record to hold the data
};

const timeTableSlice = createSlice({
  name: "timeTable",
  initialState,
  reducers: {
    setStartTime: (state, action) => {
      state.startTime = action.payload;
    },
    setEndTime: (state, action) => {
      state.endTime = action.payload;
    },
    setDay: (state, action) => {
      state.day = action.payload;
    },
    setRecord: (state, action) => {
      state.record = action.payload;
    },
  },
});

export const { setStartTime, setEndTime, setDay, setRecord } =
  timeTableSlice.actions;

export default timeTableSlice.reducer;
