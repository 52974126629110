import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Create Context
const AppContext = createContext();

// Create a Provider Component
export const AssignProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    grade: "",
    section: "",
    type: "",
    selectedData: null,
  });

  const [dropdownStates, setDropdownStates] = useState({});
  const location = useLocation();

  useEffect(() => {
    // Define the routes where you don't want to reset the appState
    const excludedRoutes = [
      "/school/points/assign",
      "/school/behaviour/assign/classes/view",
    ];

    // Check if the current route is not one of the excluded routes
    if (!excludedRoutes.includes(location.pathname)) {
      setAppState({
        grade: "",
        section: "",
        type: "",
        selectedData: null,
      });
    }
  }, [location.pathname]); // Triggered when the location changes

  return (
    <AppContext.Provider
      value={{ appState, setAppState, dropdownStates, setDropdownStates }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom Hook for easier use
export const useAssignContext = () => {
  return useContext(AppContext);
};
