import React from "react";
import { MonthView } from "react-calendar";
import Template from "../views/School-admin/Template/Template";
import TemplateEdit from "../views/School-admin/Template/TemplateEdit";
import AddSubject from "../views/School-admin/AcademicYear/AddSubject";
import EditSubject from "../views/School-admin/AcademicYear/EditSubject";
import Sections from "../views/School-admin/AcademicYear/Sections";
import WorkingDays from "../views/School-admin/AcademicYear/WorkingDays";
import WorkingdaysList from "../views/School-admin/AcademicYear/WorkingdaysList";
import AddSchoolType from "../views/School-admin/ManageSchooltype/AddSchoolType";
import EditSchoolType from "../views/School-admin/ManageSchooltype/Edit";
import SchoolTypeListing from "../views/School-admin/ManageSchooltype/List";
import ClassAttendanceUpdate from "../views/Admin-Attend/DayAttendanceDetails/ClassAttendanceUpdate";
const TimeTable = React.lazy(() =>
  import("../views/School-admin/TimeTable/TimeTable")
);
const TimeTableEdit = React.lazy(() =>
  import("../views/School-admin/TimeTable/TimeTableEdit")
);
const TimeTableListing = React.lazy(() =>
  import("../views/School-admin/TimeTable/TimeTableListing")
);
const Notification = React.lazy(() =>
  import("../views/TeacherAdmin/Notification/Notification")
);
const NotificationStudent = React.lazy(() =>
  import("../views/Studentview/Notification/Notification")
);
const MarkAttendance = React.lazy(() =>
  import("../views/TeacherAdmin/Attendance/Attendance/MarkAttendance")
);
const TodayAttendance = React.lazy(() =>
  import("../views/TeacherAdmin/Attendance/TodayAttendance/TodayAttendance")
);
const LeaveRequestSchool = React.lazy(() =>
  import("../views/Admin-Attend/Attendance/LeaveRequest/Teacher/Request")
);
const LeaveRequestSchools = React.lazy(() =>
  import("../views/Admin-Attend/Attendance/LeaveRequest/Student/Request")
);
const UpdateTodayAttendance = React.lazy(() =>
  import(
    "../views/TeacherAdmin/Attendance/TodayAttendance/UpdateTodayAttendance"
  )
);
const EditClassAttend = React.lazy(() =>
  import("../views/Admin-Attend/classattend/editClassAttent")
);
const EditAttend = React.lazy(() =>
  import("../views/Admin-Attend/Attend-list/editAttent")
);

const RequestList = React.lazy(() =>
  import("../views/Admin-Attend/Attendance/LeaveRequest/Teacher/RequestList")
);
const RequestLists = React.lazy(() =>
  import("../views/Admin-Attend/Attendance/LeaveRequest/Student/RequestList")
);

const AttendenceList = React.lazy(() =>
  import("../views/Studentview/Attendance/Attendance/List")
);

const Certificates = React.lazy(() =>
  import("../views/Studentview/Attendance/Attendance/Certificates")
);
const AttendanceListing = React.lazy(() =>
  import("../views/Studentview/Attendance/Attendance/AttendanceListing")
);
const EditRequest = React.lazy(() =>
  import("../views/TeacherAdmin/Attendance/LeaveRequest/EditRequest")
);
const LeaveRequest = React.lazy(() =>
  import("../views/TeacherAdmin/Attendance/LeaveRequest/LeaveRequest")
);
const viewTeacherLeaveRequest = React.lazy(() =>
  import("../views/TeacherAdmin/Attendance/LeaveRequest/View")
);

const Dashboard = React.lazy(() => import("../views/Dashboard/Dashboard"));
const Addschool = React.lazy(() => import("../views/Manageschools/Addschool"));
const Addstudent = React.lazy(() =>
  import("../views/Managestudents/Addstudent")
);
const Importstudent = React.lazy(() =>
  import("../views/Managestudents/Importstudent")
);
const Listingpage = React.lazy(() =>
  import("../views/Manageschools/Listingpage")
);
const Editschool = React.lazy(() =>
  import("../views/Manageschools/Editschool")
);
const Studentlisting = React.lazy(() =>
  import("../views/Managestudents/StudentListing")
);
const Editstudent = React.lazy(() =>
  import("../views/Managestudents/Editstudent")
);
const Addteacher = React.lazy(() =>
  import("../views/Manageteachers/Addteacher")
);

// admin Attendlist
const Attendlist = React.lazy(() =>
  import("../views/Admin-Attend/Attend-list/list")
);
const ClassAttendanceDetails = React.lazy(() =>
  import("../views/Admin-Attend/DayAttendanceDetails/ClassAttendanceDetails")
);
const ClassAttendanceDetailsList = React.lazy(() =>
  import(
    "../views/Admin-Attend/DayAttendanceDetails/ClassAttendanceDetailsList"
  )
);
const MarkClassAttendance = React.lazy(() =>
  import("../views/Admin-Attend/DayAttendanceDetails/MarkClassAttendance")
);

const AttendAdd = React.lazy(() =>
  import("../views/Admin-Attend/Attend-list/addattend")
);

const ClassAttendance = React.lazy(() =>
  import("../views/Admin-Attend/classattend/list")
);

const AddClassAttend = React.lazy(() =>
  import("../views/Admin-Attend/classattend/addclassattend")
);

const ManualScanlist = React.lazy(() =>
  import("../views/Admin-Attend/ManualScan/list")
);

const Daysummary = React.lazy(() =>
  import("../views/Admin-Attend/DaySummary/list")
);

const DayAttendanceDetails = React.lazy(() =>
  import("../views/Admin-Attend/DayAttendanceDetails/list")
);

const TeacherDayAttendance = React.lazy(() =>
  import("../views/Admin-Attend/Teacher Attendance/list")
);
const TeacherDayMark = React.lazy(() =>
  import("../views/Admin-Attend/Teacher Attendance/addMark")
);

const CertificateList = React.lazy(() =>
  import("../views/Admin-Attend/Certificate/list")
);

const CertificateAdd = React.lazy(() =>
  import("../views/Admin-Attend/Certificate/AddCategory")
);
// const MontlyReportView = React.lazy(() => {
//    import("../views/Studentview/Behaviour/Report/ReportView")
// })

const MontlyReportView = React.lazy(() =>
  import("../views/Studentview/Behaviour/Report/ReportView")
);
const Teacherlisting = React.lazy(() =>
  import("../views/Manageteachers/Teacherlisting")
);

const Editteacher = React.lazy(() =>
  import("../views/Manageteachers/Editteacher")
);
const CategoryListing = React.lazy(() =>
  import("../views/Categories/CategoryListing")
);
const Addcategory = React.lazy(() => import("../views/Categories/AddCategory"));
const Editcategory = React.lazy(() =>
  import("../views/Categories/EditCategory")
);
const AdaatListing = React.lazy(() => import("../views/Adaats/AdaatListing"));
const AddAdaat = React.lazy(() => import("../views/Adaats/AddAdaat"));

const StudentView = React.lazy(() =>
  import("../views/Studentview/StudentView")
);
const Sibling = React.lazy(() => import("../views/Studentview/Sibling/list"));
const AddSibling = React.lazy(() => import("../views/Studentview/Sibling/add"));

const ProfileView = React.lazy(() =>
  import("../views/Studentview/ProfileView")
);

const MiqaatListing = React.lazy(() =>
  import("../views/Miqaats/MiqaatListing")
);

const AddMiqaat = React.lazy(() => import("../views/Miqaats/AddMiqaat"));
const EditMiqaat = React.lazy(() => import("../views/Miqaats/EditMiqaat"));

const Settings = React.lazy(() => import("../views/Settings/Settings"));

const EditAdaat = React.lazy(() => import("../views/Adaats/EditAdaat"));
const Reports = React.lazy(() => import("../views/Studentview/Reports"));
const changePassword = React.lazy(() => import("../views/ChangePassword"));
const ForgotPassword = React.lazy(() => import("../views/ForgotPassword"));
const studentLeave = React.lazy(() =>
  import("../views/Studentview/Attandance/leave/list")
);
const studentLeaveCreate = React.lazy(() =>
  import("../views/Studentview/Attandance/leave/add")
);
const studentLeaveEdit = React.lazy(() =>
  import("../views/Studentview/Attandance/leave/edit")
);

const studentLeaveView = React.lazy(() =>
  import("../views/Studentview/Attandance/leave/View")
);

// Notification

const NotificationReceived = React.lazy(() =>
  import("../views/School-admin/Notification/NotificationList/Received/list")
);
const NotificationSend = React.lazy(() =>
  import("../views/School-admin/Notification/NotificationList/Send/SendListing")
);
const NotificationReceivedAdd = React.lazy(() =>
  import(
    "../views/School-admin/Notification/NotificationList/Received/AddReceived"
  )
);

const BirthDayListing = React.lazy(() =>
  import("../views/School-admin/Notification/BirthDayListing")
);

const EditDay = React.lazy(() =>
  import("../views/School-admin/AcademicYear/WorkingDaysEdit")
);

const EditNoti = React.lazy(() =>
  import(
    "../views/School-admin/Notification/NotificationList/Received/EditReceived"
  )
);

// Modules --------------------------------------------------------
const ModuleListing = React.lazy(() =>
  import("../views/Modules/ModuleListing")
);
const AddModules = React.lazy(() => import("../views/Modules/AddModules"));
const EditModules = React.lazy(() => import("../views/Modules/NewEditModule"));

// School Admin --------------------------------------------------------

const SchoolDashboard = React.lazy(() =>
  import("../views/School-admin/Dashboard/Dashboard")
);
const SchoolForgotPassword = React.lazy(() =>
  import("../views/School-admin/ForgotPassword/ForgotPassword")
);
const SchoolChangePassword = React.lazy(() =>
  import("../views/School-admin/ChangePassword/ChangePassword")
);

// School Admin Students
const schoolStudents = React.lazy(() =>
  import("../views/School-admin/Managestudents/StudentListing")
);
const schoolAddStudents = React.lazy(() =>
  import("../views/School-admin/Managestudents/Addstudent")
);

const schoolEditStudents = React.lazy(() =>
  import("../views/School-admin/Managestudents/Editstudent")
);
const schoolImportStudents = React.lazy(() =>
  import("../views/School-admin/Managestudents/Importstudent")
);

const Editcertif = React.lazy(() =>
  import("../views/Admin-Attend/Certificate/Editcertificate")
);
// School Admin Teachers
const schoolTeachers = React.lazy(() =>
  import("../views/School-admin/ManageSchoolteachers/TeacherSchoollisting")
);
const schoolAddTeachers = React.lazy(() =>
  import("../views/School-admin/ManageSchoolteachers/AddSchoolteacher")
);
const schoolEditTeachers = React.lazy(() =>
  import("../views/School-admin/ManageSchoolteachers/EditSchoolteacher")
);
// School Admin Allergies
const AllergiesListing = React.lazy(() =>
  import("../views/School-admin/ManageAllergies/AllergiesListing")
);
const EditAllergies = React.lazy(() =>
  import("../views/School-admin/ManageAllergies/EditAllergies")
);
const AddAllergies = React.lazy(() =>
  import("../views/School-admin/ManageAllergies/AddAllergies")
);
// School Admin House
const HouseListing = React.lazy(() =>
  import("../views/School-admin/ManageHouse/HouseListing")
);
const AddHouse = React.lazy(() =>
  import("../views/School-admin/ManageHouse/AddHouse")
);
const EditHouse = React.lazy(() =>
  import("../views/School-admin/ManageHouse/EditHouse")
);
// School Admin Term
const TermList = React.lazy(() =>
  import("../views/School-admin/ManageTerm/TermListing")
);
const AcademicListing = React.lazy(() =>
  import("../views/School-admin/AcademicYear/AcademicListing")
);
const AcademicAdd = React.lazy(() =>
  import("../views/School-admin/AcademicYear/AddAcademic")
);
const AcademicEdit = React.lazy(() =>
  import("../views/School-admin/AcademicYear/EditAcademic")
);
const AddTerm = React.lazy(() =>
  import("../views/School-admin/ManageTerm/AddTerm")
);
const EditTerm = React.lazy(() =>
  import("../views/School-admin/ManageTerm/EditTerm")
);

// School Admin Aadat
const AadatList = React.lazy(() =>
  import("../views/School-admin/ManageSchoolAdaat/AdaatListing")
);
const EditAadat = React.lazy(() =>
  import("../views/School-admin/ManageSchoolAdaat/newtemplateditaadat")
);
const AddAadat = React.lazy(() =>
  import("../views/School-admin/ManageSchoolAdaat/AddAdaat")
);

// School Admin Teacger Type
const teacherTypeList = React.lazy(() =>
  import("../views/School-admin/TeacherType/list")
);

const teacherTypeAdd = React.lazy(() =>
  import("../views/School-admin/TeacherType/add")
);
const teacherTypeEdit = React.lazy(() =>
  import("../views/School-admin/TeacherType/edit")
);
const teacherRequestCoupon = React.lazy(() =>
  import("../views/TeacherAdmin/Behaviour/Assigned/list")
);

const teacherimport = React.lazy(() =>
  import("../views/School-admin/ManageSchoolteachers/importTeacher")
);

const AddLevel = React.lazy(() =>
  import("../views/School-admin/Academic/Level/AddLevel")
);

const LevelList = React.lazy(() =>
  import("../views/School-admin/Academic/Level/LevelList")
);

// School Admin GRADE
const gradeList = React.lazy(() => import("../views/School-admin/Grade/list"));
const AddGrade = React.lazy(() => import("../views/School-admin/Grade/add"));
const editGrade = React.lazy(() => import("../views/School-admin/Grade/edit"));

// SCHOOL ADMIN BEHAVIOUR POINTS
const ReportList = React.lazy(() =>
  import("../views/School-admin/Behaviour/Report/ReportSection")
);
const pointsList = React.lazy(() =>
  import("../views/School-admin/Behaviour/points/list")
);
const pointsAdd = React.lazy(() =>
  import("../views/School-admin/Behaviour/points/addpoints")
);
const pointsEdit = React.lazy(() =>
  import("../views/School-admin/Behaviour/points/EditPoints")
);

const behaviourCategoryList = React.lazy(() =>
  import("../views/School-admin/Behaviour/category/list")
);
const behaviourCategoryCreate = React.lazy(() =>
  import("../views/School-admin/Behaviour/category/addcategory")
);
const behaviourCategoryEdit = React.lazy(() =>
  import("../views/School-admin/Behaviour/category/edit")
);

const behaviourCouponApproval = React.lazy(() =>
  import("../views/School-admin/Behaviour/Coupon/Approval/list")
);
const behaviourleaderboard = React.lazy(() =>
  import("../views/School-admin/Behaviour/Leaderboard/leaderboard")
);
const behaviourleaderboardPointCategorywise = React.lazy(() =>
  import("../views/School-admin/Behaviour/Leaderboard/categoryWisePoints/list")
);
const behaviourCouponList = React.lazy(() =>
  import("../views/School-admin/Behaviour/Coupon/CouponList/List")
);
const behaviourCouponAddcoupon = React.lazy(() =>
  import("../views/School-admin/Behaviour/Coupon/AddCoupon/AddCoupon")
);
const behaviourCouponEdit = React.lazy(() =>
  import("../views/School-admin/Behaviour/Coupon/EditCoupon/EditCoupon")
);

const behaviourCategoryWisePoints = React.lazy(() =>
  import("../views/School-admin/Behaviour/Leaderboard/categoryWisePoints/list")
);

const behaviourViewClassWisePoints = React.lazy(() =>
  import("../views/School-admin/Behaviour/points/ViewClassWisepoints/list")
);
const behaviourAssignClassWisePoints = React.lazy(() =>
  import("../views/School-admin/Behaviour/Assign Points/classwiseSelect/list")
);

const behaviourSinglePointsView = React.lazy(() =>
  import("../views/School-admin/Behaviour/points/SingleView/list")
);

const schoolPoints = React.lazy(() =>
  import("../views/School-admin/Behaviour/adminPoints/page")
);

const schoolPointsAssign = React.lazy(() =>
  import("../views/School-admin/Behaviour/Assign Points/assign")
);

// TEACHER ROUTES START

const teacherProfileEdit = React.lazy(() =>
  import("../views/TeacherAdmin/profile/profile")
);
const teacherCouponList = React.lazy(() =>
  import("../views/TeacherAdmin/Behaviour/Coupon/List")
);
const teacherDashboard = React.lazy(() =>
  import("../views/TeacherAdmin/Dashboard/Dashboard")
);
const teacherBehaviourDashboard = React.lazy(() =>
  import("../views/TeacherAdmin/Behaviour/leaderboard/Page")
);

const teacherBehaviourPointView = React.lazy(() =>
  import("../views/TeacherAdmin/Behaviour/PointsView/IndividualView/list")
);

const teacherBehaviourPointAssign = React.lazy(() =>
  import("../views/TeacherAdmin/Behaviour/Assigned/Assign Points/assign")
);
const teacherBehaviourPointClassWise = React.lazy(() =>
  import(
    "../views/TeacherAdmin/Behaviour/Assigned/Assign Points/classwiseSelect/list"
  )
);

const teacherBehaviourPointClassView = React.lazy(() =>
  import("../views/TeacherAdmin/Behaviour/PointsView/classwise/list")
);
const teacherBehaviourPointClassStudentView = React.lazy(() =>
  import(
    "../views/TeacherAdmin/Behaviour/PointsView/individualStudentView/list"
  )
);

// student routes

const studentBehaviourDashboard = React.lazy(() =>
  import("../views/Studentview/Behaviour/leaderboard/Page")
);
const studentBehaviourPointView = React.lazy(() =>
  import("../views/Studentview/Behaviour/Assigned/list")
);
const studentCouponList = React.lazy(() =>
  import("../views/Studentview/Behaviour/Coupon/List")
);
const studentRequestCoupon = React.lazy(() =>
  import("../views/Studentview/Behaviour/Assigned/list")
);
const studentBehaviourPointsView = React.lazy(() =>
  import("../views/Studentview/Behaviour/PointsView/IndividualView/list")
);
const studentBehaviourPointAssign = React.lazy(() =>
  import("../views/Studentview/Behaviour/Assigned/Assign Points/assign")
);

const AssignPointsbyschool = React.lazy(() =>
  import("../views/School-admin/Behaviour/adminPoints/AssignPoints")
);

const AssignPointstpschool = React.lazy(() =>
  import("../views/School-admin/Behaviour/adminPoints/CategoryAssign")
);

const studentBehaviourPointClassWise = React.lazy(() =>
  import(
    "../views/Studentview/Behaviour/Assigned/Assign Points/classwiseSelect/list"
  )
);
const studentBehaviourReport = React.lazy(() =>
  import("../views/Studentview/Behaviour/Report/MonthlyReport")
);

const AssignTeacher = React.lazy(() =>
  import("../views/School-admin/AcademicYear/AssignTeacher/AssignTeacher")
);

const AddAssignTeacher = React.lazy(() =>
  import("../views/School-admin/AcademicYear/AssignTeacher/AddAssignTea")
);
const routes = [
  { path: "/dashboard", name: "Dashboard", element: Dashboard, role: "admin" },
  {
    path: "/attendance",
    name: "Attendance",
    element: Attendlist,
    role: "school-admin",
  },

  {
    path: "/attendance/addattendance",
    name: "AddAttendance",
    element: AttendAdd,
    role: "school-admin",
  },
  {
    path: "/attendance/editattendance",
    name: "EditAttend",
    element: EditAttend,
    role: "school-admin",
  },
  {
    path: "/classattendance",
    name: "ClassAttendance",
    element: ClassAttendance,
    role: "school-admin",
  },
  {
    path: "/AddClassAttend",
    name: "AddClassAttendance",
    element: AddClassAttend,
    role: "school-admin",
  },
  {
    path: "/EditClassAttend",
    name: "EditClassAttend",
    element: EditClassAttend,
    role: "school-admin",
  },
  {
    path: "/dayattendancemanualscan",
    name: "ManualScanlist",
    element: ManualScanlist,
    role: "school-admin",
  },

  {
    path: "/Dayattendancesummary",
    name: "Daysummary",
    element: Daysummary,
    role: "school-admin",
  },

  {
    path: "/DayAttendanceDetails",
    name: "DayAttendanceDetails",
    element: DayAttendanceDetails,
    role: "school-admin",
  },
  {
    path: "ClassAttendanceDetails/:id/:firstName/:type",
    name: "ClassAttendanceDetails",
    element: ClassAttendanceDetails,
    role: "school-admin",
  },
  {
    path: "ClassAttendanceDetails/update/:id",
    name: "ClassAttendanceDetails",
    element: ClassAttendanceUpdate,
    role: "school-admin",
  },
  {
    path: "ClassAttendanceDetails/listing",
    name: "ClassAttendanceDetails",
    element: ClassAttendanceDetailsList,
    role: "school-admin",
  },
  {
    path: "/MarkClassAttendance",
    name: "MarkClassAttendance",
    element: MarkClassAttendance,
    role: "school-admin",
  },
  {
    path: "/Teachersdayattendance",
    name: "TeacherDayAttendance",
    element: TeacherDayAttendance,
    role: "school-admin",
  },
  {
    path: "/Timetable",
    name: "TimeTable",
    element: TimeTable,
    role: "school-admin",
  },
  {
    path: "/Timetable/:id",
    name: "TimeTableEdit",
    element: TimeTableEdit,
    role: "school-admin",
  },
  {
    path: "/Timetable/Listing",
    name: "TimeTableListing",
    element: TimeTableListing,
    role: "school-admin",
  },

  {
    path: "/leave/request/teacher",
    name: "LeaveRequestSchool",
    element: LeaveRequestSchool,
    role: "school-admin",
  },

  {
    path: "/leave/request/list/teacher",
    name: "RequestList",
    element: RequestList,
    role: "school-admin",
  },

  {
    path: "/leave/request/student",
    name: "LeaveRequestSchools",
    element: LeaveRequestSchools,
    role: "school-admin",
  },

  {
    path: "/leave/request/list/student",
    name: "RequestLists",
    element: RequestLists,
    role: "school-admin",
  },
  {
    path: "/TeacherDayMark",
    name: "TeacherDayMark",
    element: TeacherDayMark,
    role: "school-admin",
  },
  {
    path: "/CertificateList",
    name: "CertificateList",
    element: CertificateList,
    role: "school-admin",
  },
  {
    path: "/CertificateAdd",
    name: "CertificateAdd",
    element: CertificateAdd,
    role: "school-admin",
  },
  {
    path: "/school/EditCertificate",
    name: "CertificateEdit",
    element: Editcertif,
    role: "school-admin",
  },
  {
    path: "/addstudent",
    name: "Addstudent",
    element: Addstudent,
    role: "admin",
  },
  {
    path: "/editstudent",
    name: "Editstudent",
    element: Editstudent,
    role: "admin",
  },
  {
    path: "/importstudent",
    name: "Importstudent",
    element: Importstudent,
    role: "admin",
  },
  {
    path: "/studentlisting",
    name: "Studentlisting",
    element: Studentlisting,
    role: "admin",
  },
  { path: "/addschool", name: "Addschool", element: Addschool, role: "admin" },
  {
    path: "/schoollisting",
    name: "Schoollisting",
    element: Listingpage,
    role: "admin",
  },
  {
    path: "/editschool",
    name: "Editschool",
    element: Editschool,
    role: "admin",
  },
  {
    path: "/addteacher",
    name: "Addteacher",
    element: Addteacher,
    role: "admin",
  },
  {
    path: "/teacherlisting",
    name: "Teacherlisting",
    element: Teacherlisting,
    role: "admin",
  },
  {
    path: "/editteacher",
    name: "Editteacher",
    element: Editteacher,
    role: "admin",
  },

  {
    path: "/school/categories",
    name: "CategoryListing",
    element: CategoryListing,
    role: "school-admin",
  },
  {
    path: "/school/create/category",
    name: "Addcategory",
    element: Addcategory,
    role: "school-admin",
  },
  {
    path: "/school/edit/category",
    name: "Editcategory",
    element: Editcategory,
    role: "school-admin",
  },
  {
    path: "/school/timeTable",
    name: "TimeTable",
    element: TimeTable,
    role: "school-admin",
  },

  {
    path: "/adaatlisting",
    name: "AdaatListing",
    element: AdaatListing,
    role: "admin",
  },

  {
    path: "/addadaat",
    name: "AddAdaat",
    element: AddAdaat,
    role: "admin",
  },
  {
    path: "/editadaat",
    name: "EditAdaat",
    element: EditAdaat,
    role: "admin",
  },

  {
    path: "/school/settings",
    name: "Settings",
    element: Settings,
    role: "school-admin",
  },

  // frontend for students/parents

  // for attendance route

  {
    path: "/student/attendance/list",
    name: "AttendenceList",
    element: AttendenceList,
    role: "student",
  },

  {
    path: "/student/attendance/certificates",
    name: "Certificates",
    element: Certificates,
    role: "student",
  },

  {
    path: "/student/attendance/listing",
    name: "AttendanceListing",
    element: AttendanceListing,
    role: "student",
  },

  // for attendance route

  {
    path: "/student/behaviour/leaderboard",
    name: "studentBehaviourDashboard",
    element: studentBehaviourDashboard,
    role: "student",
  },
  {
    path: "/student/behaviour/points",
    name: "studentBehaviourPointView",
    element: studentBehaviourPointView,
    role: "student",
  },
  {
    path: "/student/behaviour/report/view",
    name: "MontlyReportViewstudent",
    element: MontlyReportView,
    role: "student",
  },
  {
    path: "/student/coupons",
    name: "studentCouponList",
    element: studentCouponList,
    role: "student",
  },
  {
    path: "/student/points/assigned",
    name: "studentRequestCoupon",
    element: studentRequestCoupon,
    role: "student",
  },
  {
    path: "/student/assign/point/classes/view",
    name: "studentBehaviourPointClassWise",
    element: studentBehaviourPointClassWise,
    role: "student",
  },

  {
    path: "/student/points/view",
    name: "studentBehaviourPointsView",
    element: studentBehaviourPointsView,
    role: "student",
  },

  {
    path: "/student/points/assign",
    name: "studentBehaviourPointAssign",
    element: studentBehaviourPointAssign,
    role: "student",
  },

  {
    path: "/student/report",
    name: "studentBehaviourReport",
    element: studentBehaviourReport,
    role: "student",
  },

  {
    path: "/studentview",
    name: "StudentView",
    element: StudentView,
    role: "student",
  },
  {
    path: "/siblings",
    name: "Sibling",
    element: Sibling,
    role: "student",
  },
  {
    path: "/create/siblings",
    name: "AddSibling",
    element: AddSibling,
    role: "student",
  },
  {
    path: "/profileview",
    name: "ProfileView",
    element: ProfileView,
    role: "student",
  },
  {
    path: "/reports",
    name: "Report",
    element: Reports,
    role: "student",
  },

  {
    path: "/change-password",
    name: "Report",
    element: changePassword,
    role: "student",
  },

  {
    path: "/Forgot-Password",
    name: "Forgot-Password",
    element: ForgotPassword,
    role: "student",
  },
  {
    path: "/student/leave",
    name: "studentLeave",
    element: studentLeave,
    role: "student",
  },
  {
    path: "/student/leave/create",
    name: "studentLeaveCreate",
    element: studentLeaveCreate,
    role: "student",
  },
  {
    path: "/student/leave/edit",
    name: "studentLeaveEdit",
    element: studentLeaveEdit,
    role: "student",
  },
  {
    path: "/student/leave/view",
    name: "studentLeaveView",
    element: studentLeaveView,
    role: "student",
  },

  // Notification
  {
    path: "/school/Notification/template",
    name: "Template",
    element: Template,
    role: "school-admin",
  },
  {
    path: "/school/Notification/template/edit",
    name: "TemplateEdit",
    element: TemplateEdit,
    role: "school-admin",
  },
  {
    path: "/school/Notification/receivedlist",
    name: "NotificationReceived",
    element: NotificationReceived,
    role: "school-admin",
  },
  {
    path: "/school/Notification/sendlist",
    name: "NotificationSend",
    element: NotificationSend,
    role: "school-admin",
  },
  {
    path: "/school/EditDay",
    name: "EditDay",
    element: EditDay,
    role: "school-admin",
  },
  {
    path: "/school/Notification/addnotifaction",
    name: "NotificationReceivedAdd",
    element: NotificationReceivedAdd,
    role: "school-admin",
  },
  {
    path: "/school/Notification/EditNoti",
    name: "NotificationReceivedEdit",
    element: EditNoti,
    role: "school-admin",
  },

  // Modules

  {
    path: "/school/Notification/birthDayListing",
    name: "BirthDayListing",
    element: BirthDayListing,
    role: "school-admin",
  },
  {
    path: "/ModuleListing",
    name: "ModuleListing",
    element: ModuleListing,
    role: "admin",
  },

  {
    path: "/addModules",
    name: "AddModules",
    element: AddModules,
    role: "admin",
  },

  {
    path: "/editModules",
    name: "AddModules",
    element: EditModules,
    role: "admin",
  },

  // Schoool Admin

  {
    path: "/school/dashboard",
    name: "SchoolDashboard",
    element: SchoolDashboard,
    role: "school-admin",
  },
  {
    path: "/school/AssignTeacher",
    name: "AssignTeacherList",
    element: AssignTeacher,
    role: "school-admin",
  },

  {
    path: "/school/AddAssignTeacher",
    name: "AssignTeacherAdd",
    element: AddAssignTeacher,
    role: "school-admin",
  },

  {
    path: "/school/manageStudent",
    name: "schoolStudents",
    element: schoolStudents,
    role: "school-admin",
  },
  {
    path: "/school/addStudent",
    name: "schoolAddStudents",
    element: schoolAddStudents,
    role: "school-admin",
  },
  {
    path: "/school/editStudent",
    name: "schoolEdittudents",
    element: schoolEditStudents,
    role: "school-admin",
  },
  {
    path: "/school/importStudent",
    name: "schoolImportStudents",
    element: schoolImportStudents,
    role: "school-admin",
  },
  {
    path: "/school/teachers",
    name: "schoolTeachers",
    element: schoolTeachers,
    role: "school-admin",
  },
  {
    path: "/school/editTeacher",
    name: "schoolEditTeachers",
    element: schoolEditTeachers,
    role: "school-admin",
  },
  {
    path: "/school/addTeacher",
    name: "schoolAddTeachers",
    element: schoolAddTeachers,
    role: "school-admin",
  },
  {
    path: "/school/teacher/import",
    name: "schoolTeacherImport",
    element: teacherimport,
    role: "school-admin",
  },
  {
    path: "/school/teachers/type",
    name: "schoolAddTeachers",
    element: teacherTypeList,
    role: "school-admin",
  },
  {
    path: "/school/teachers/type/create",
    name: "schoolAddTeachers",
    element: teacherTypeAdd,
    role: "school-admin",
  },
  {
    path: "/school/teachers/type/edit",
    name: "schoolAddTeachers",
    element: teacherTypeEdit,
    role: "school-admin",
  },

  {
    path: "/school/allergies",
    name: "AllergiesListing",
    element: AllergiesListing,
    role: "school-admin",
  },
  {
    path: "/school/addallergies",
    name: "AddAllergies",
    element: AddAllergies,
    role: "school-admin",
  },
  {
    path: "/school/add-school-type",
    name: "AddSchoolType",
    element: AddSchoolType,
    role: "school-admin",
  },
  {
    path: "/school/edit-school-type",
    name: "EditSchoolType",
    element: EditSchoolType,
    role: "school-admin",
  },
  {
    path: "/school/school-type",
    name: "SchoolTypeListing",
    element: SchoolTypeListing,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/students/points/assign",
    name: "AssignPointsbyschool",
    element: AssignPointsbyschool,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/admin/points/assign",
    name: "AssignPointstpschool",
    element: AssignPointstpschool,
    role: "school-admin",
  },

  // student attendance routes

  {
    path: "/school/editallergies",
    name: "EditAllergies",
    element: EditAllergies,
    role: "school-admin",
  },

  {
    path: "/school/house",
    name: "HouseListing",
    element: HouseListing,
    role: "school-admin",
  },
  {
    path: "/school/edithouse",
    name: "EditHouse",
    element: EditHouse,
    role: "school-admin",
  },
  {
    path: "/school/addhouse",
    name: "AddHouse",
    element: AddHouse,
    role: "school-admin",
  },
  {
    path: "/school/term",
    name: "term",
    element: TermList,
    role: "school-admin",
  },
  {
    path: "/school/AcademicYear",
    name: "AcademicYear",
    element: AcademicListing,
    role: "school-admin",
  },

  {
    path: "/school/add-subjects",
    name: "AddSubject",
    element: AddSubject,
    role: "school-admin",
  },
  {
    path: "/school/classwork",
    name: "ClassWork",
    element: AddSubject,
    role: "school-admin",
  },
  {
    path: "/school/add-working-days",
    name: "WorkingDays",
    element: WorkingDays,
    role: "school-admin",
  },

  {
    path: "/school/sections",
    name: "Sections",
    element: Sections,
    role: "school-admin",
  },
  {
    path: "/school/working-days-listing",
    name: "WorkingdaysList",
    element: WorkingdaysList,
    role: "school-admin",
  },
  {
    path: "/school/edit-subjects",
    name: "EditSubject",
    element: EditSubject,
    role: "school-admin",
  },
  {
    path: "/school/AddLevel",
    name: "AddLevel",
    element: AddLevel,
    role: "school-admin",
  },
  {
    path: "/school/LevelList",
    name: "Levellist",
    element: LevelList,
    role: "school-admin",
  },
  {
    path: "/school/AcademicEdit",
    name: "AcademicEdit",
    element: AcademicEdit,
    role: "school-admin",
  },
  {
    path: "/school/AcademicAdd",
    name: "AcademicAdd",
    element: AcademicAdd,
    role: "school-admin",
  },
  {
    path: "/school/addterm",
    name: "term",
    element: AddTerm,
    role: "school-admin",
  },
  {
    path: "/school/editterm",
    name: "editterm",
    element: EditTerm,
    role: "school-admin",
  },
  {
    path: "/school/Aadats",
    name: "aadats",
    element: AadatList,
    role: "school-admin",
  },
  {
    path: "/school/editAadat",
    name: "editAadat",
    element: EditAadat,
    role: "school-admin",
  },
  {
    path: "/school/addAadat",
    name: "addAadat",
    element: AddAadat,
    role: "school-admin",
  },

  {
    path: "/school/forgotpassword",
    name: "SchoolForgotPassword",
    element: SchoolForgotPassword,
    role: "school-admin",
  },
  {
    path: "/school/changepassword",
    name: "SchoolChangePassword",
    element: SchoolChangePassword,
    role: "school-admin",
  },
  {
    path: "/school/grade/create",
    name: "SchoolAddGrade",
    element: AddGrade,
    role: "school-admin",
  },

  {
    path: "/school/grade/",
    name: "SchoolAddGrade",
    element: gradeList,
    role: "school-admin",
  },
  {
    path: "/school/grade/edit",
    name: "SchoolAddGrade",
    element: editGrade,
    role: "school-admin",
  },

  {
    path: "/school/miqaat",
    name: "MiqaatListing",
    element: MiqaatListing,
    role: "school-admin",
  },

  {
    path: "/school/miqaat/create",
    name: "AddMiqaat",
    element: AddMiqaat,
    role: "school-admin",
  },
  {
    path: "/school/miqaat/edit",
    name: "EditMiqaat",
    element: EditMiqaat,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/points",
    name: "Points",
    element: pointsList,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/report",
    name: "Report",
    element: ReportList,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/point/add",
    name: "PointAdd",
    element: pointsAdd,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/point/edit",
    name: "pointsEdit",
    element: pointsEdit,
    role: "school-admin",
  },

  {
    path: "/school/behaviour/categories",
    name: "BehaviourCategory",
    element: behaviourCategoryList,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/category/create",
    name: "BehaviourCategoryCreate",
    element: behaviourCategoryCreate,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/category/edit",
    name: "BehaviourCategoryEdit",
    element: behaviourCategoryEdit,
    role: "school-admin",
  },

  {
    path: "/school/behaviour/Coupons/request",
    name: "BehaviourCouponApproval",
    element: behaviourCouponApproval,
    role: "school-admin",
  },

  {
    path: "/school/behaviour/Coupons/editCoupon",
    name: "behaviourCouponEdit",
    element: behaviourCouponEdit,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/Coupons/list",
    name: "behaviourCouponList",
    element: behaviourCouponList,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/Coupons/addCoupon",
    name: "behaviourCouponAddcoupon",
    element: behaviourCouponAddcoupon,
    role: "school-admin",
  },

  {
    path: "/school/behaviour/leaderboard",
    name: "Behaviourleaderboard",
    element: behaviourleaderboard,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/points/categorywise",
    name: "behaviourleaderboardPointCategorywise",
    element: behaviourleaderboardPointCategorywise,
    role: "school-admin",
  },

  {
    path: "/school/behaviour/category/points",
    name: "Behaviourcategorypoints",
    element: behaviourCategoryWisePoints,
    role: "school-admin",
  },

  {
    path: "/school/behaviour/points/view",
    name: "BehaviouryPointsView",
    element: behaviourSinglePointsView,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/points/classes/view",
    name: "BehaviouryPointsCategoryWise",
    element: behaviourViewClassWisePoints,
    role: "school-admin",
  },
  {
    path: "/school/behaviour/assign/classes/view",
    name: "behaviourAssignClassWisePoints",
    element: behaviourAssignClassWisePoints,
    role: "school-admin",
  },

  {
    path: "/school/points",
    name: "schoolPoints",
    element: schoolPoints,
    role: "school-admin",
  },
  {
    path: "/school/points/assign",
    name: "schoolPointsAssign",
    element: schoolPointsAssign,
    role: "school-admin",
  },
  /// tEACHER ROUTES START

  // teacher notification module

  {
    path: "/teacher/notification/list",
    name: "Notification",
    element: Notification,
    role: "teacher",
  },

  {
    path: "/student/notification/list",
    name: "  NotificationStudent",
    element: NotificationStudent,
    role: "student",
  },

  //   TEACHER ATTENDANCE MODULE

  {
    path: "/teacher/attendance/view",
    name: "viewTeacherLeaveRequest",
    element: viewTeacherLeaveRequest,
    role: "teacher",
  },

  {
    path: "/teacher/attendance/today",
    name: "TodayAttendance",
    element: TodayAttendance,
    role: "teacher",
  },
  {
    path: "/teacher/attendance/today/update",
    name: "UpdateTodayAttendance",
    element: UpdateTodayAttendance,
    role: "teacher",
  },

  {
    path: "/teacher/attendance/markattendance",
    name: "MarkAttendance",
    element: MarkAttendance,
    role: "teacher",
  },
  {
    path: "/teacher/attendance/edit",
    name: "EditRequest",
    element: EditRequest,
    role: "teacher",
  },
  {
    path: "/teacher/attendance/leave/request",
    name: "LeaveRequest",
    element: LeaveRequest,
    role: "teacher",
  },

  {
    path: "/changepassword",
    name: "change-password",
    element: changePassword,
    role: "teacher",
  },
  {
    path: "/teacher/profile/edit",
    name: "teacherProfileEdit",
    element: teacherProfileEdit,
    role: "teacher",
  },

  {
    path: "/teacher/points/assigned",
    name: "teacherRequestCoupon",
    element: teacherRequestCoupon,
    role: "teacher",
  },

  {
    path: "/teacher/coupons",
    name: "teacherCouponList",
    element: teacherCouponList,
    role: "teacher",
  },
  // {
  //   path: "/teacher/dashboard",
  //   name: "teacherDashboard",
  //   element: teacherDashboard,
  //   role: "teacher",
  // },
  {
    path: "/teacher/behaviour/leaderboard",
    name: "teacherBehaviourDashboard",
    element: teacherBehaviourDashboard,
    role: "teacher",
  },
  {
    path: "/teacher/points/view",
    name: "teacherBehaviourPointView",
    element: teacherBehaviourPointView,
    role: "teacher",
  },

  {
    path: "/teacher/points/assign",
    name: "teacherBehaviourPointAssign",
    element: teacherBehaviourPointAssign,
    role: "teacher",
  },
  {
    path: "/teacher/assign/point/classes/view",
    name: "teacherBehaviourPointClassWise",
    element: teacherBehaviourPointClassWise,
    role: "teacher",
  },

  {
    path: "/teacher/point/classes/view",
    name: "teacherBehaviourPointClassView",
    element: teacherBehaviourPointClassView,
    role: "teacher",
  },
  {
    path: "/teacher/point/classe/student/view",
    name: "teacherBehaviourPointClassStudentView",
    element: teacherBehaviourPointClassStudentView,
    role: "teacher",
  },
];

export default routes;
