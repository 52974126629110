import { configureStore } from "@reduxjs/toolkit";
import themeSettingSlice from "./themeSettingSlice";
import sidebarSlice from "./sidebarSlice";
import timetableSlice from "./timetableSlice";
import academicYearSlice from "./academicYearSlice";

const store = configureStore({
  reducer: {
    themeSetting: themeSettingSlice,
    sidebarSlice: sidebarSlice,
    timetableSlice: timetableSlice,
    academicYear: academicYearSlice,
  },
});

export default store;
