import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CRow,
  CContainer,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormSelect,
} from "@coreui/react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Typography, Modal, notification } from "antd";
import { RotatingLines } from "react-loader-spinner";
function TemplateEdit() {
  const location = useLocation();
  console.log("locationis hree", location);
  const id = location?.state?.Id;
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    module: "",
    tags: "",
    points: "",
    condition: "",
  });

  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTemplate, setDataTemplate] = useState("");

  const getPoint = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/notification-template/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setDataTemplate(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching category:", error);
      notification.error({
        message: "Failed to get the template data",
      });
    }
  };

  useEffect(() => {
    getPoint();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/notification-template/${id}`,
        {
          module_id: dataTemplate?.module,
          condition: dataTemplate?.condition,
          remark: dataTemplate?.remark,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notification.success({
        message: "Success",
        duration: 1.5,
        description: "Template  Updated successfully!",
      });
      navigate(-1);
      resetData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error submitting form:", error);
    }
  };

  const resetData = () => {
    setValidated(false);
    setFormData({
      module: "",
      tags: "",
      points: "",
    });
  };

  const [modules, setModules] = useState([]);

  return (
    <>
      <CContainer fluid className="container overflow-auto">
        <CModal
          backdrop="static"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="StaticBackdropExampleLabel"
        >
          <CModalHeader>
            <CModalTitle id="StaticBackdropExampleLabel">
              Form Submission
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="pb-3">
            Are you sure you wish to submit this form?
          </CModalBody>
          <CModalFooter className="pl-0">
            <CButton
              color="secondary"
              className="mr-2"
              onClick={() => setVisible(false)}
            >
              Close
            </CButton>
            <CButton color="primary">Yes!</CButton>
          </CModalFooter>
        </CModal>

        <div className="Back_btn">
          <h2> Update Template</h2>
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>

        <div className="display-5 text-center pb-3 font-weight-bold">
          {" "}
          Template Information
        </div>
        {loading && (
          <div className="spinner-container">
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              strokeColor="#506EE4"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
        <CContainer fluid>
          <CForm
            className="needs-validation"
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e)}
            // Add a submit handler
          >
            <CRow className="align-items-center flex-column">
              <CCol md={6}>
                <label htmlFor="" className="form-label">
                  Module <span className="red_colour">*</span>
                </label>
                <CFormInput
                  type="text"
                  name="module"
                  placeholder="Enter module"
                  value={dataTemplate?.module}
                  disabled
                />
              </CCol>

              <CCol md={6}>
                <label htmlFor="" className="form-label">
                  Condition
                </label>
                <CFormInput
                  type="text"
                  name="condition"
                  placeholder="Enter condition"
                  value={dataTemplate?.condition}
                  disabled
                />
              </CCol>

              <CCol md={6}>
                <label htmlFor="" className="form-label">
                  Remark <span className="red_colour">*</span>
                </label>
                <CFormInput
                  type="text"
                  name="remark"
                  placeholder="Enter remark with {{x}} for dynamic points"
                  value={dataTemplate?.remark || ""}
                  onChange={(e) =>
                    setDataTemplate((prev) => ({
                      ...prev,
                      remark: e.target.value,
                    }))
                  }
                  required
                />
                {/* Helper text and warning */}
                {/* <small className="form-text text-muted">
  Ensure the text includes <code>{`{{x}}`}</code> for dynamic points. For example: <em>"You have been awarded with {`{{x}}`} points."</em>
</small>

      {!dataTemplate?.remark?.includes("{{x}}") && (
        <div className="text-danger mt-1">
          Warning: The remark does not contain <code>{`{{x}}`}</code>. Please include it for dynamic points.
        </div>
      )} */}
              </CCol>
            </CRow>

            <div className="buttons text-center pt-3">
              <CButton color="primary" type="submit">
                Save Changes
              </CButton>
              <CButton color="dark" type="reset" onClick={getPoint}>
                Reset
              </CButton>
            </div>
          </CForm>
        </CContainer>
      </CContainer>
    </>
  );
}

export default TemplateEdit;
