import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  academicYearId: null,
};

const academicYearSlice = createSlice({
  name: "academicYear",
  initialState,
  reducers: {
    setAcademicYearId: (state, action) => {
      state.academicYearId = action.payload;
    },
    clearAcademicYearId: (state) => {
      state.academicYearId = null;
    },
  },
});

export const { setAcademicYearId, clearAcademicYearId } =
  academicYearSlice.actions;

export default academicYearSlice.reducer;
