import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import user1 from "../../assets/images/users/user4.jpg";
import { AuthContext } from "../../helpers/AuthContext";
import { CToast, CToastHeader, CToastBody } from "@coreui/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { setDataLayout } from "../../redux/themeSettingSlice";

import {
  setExpandMenu,
  setMobileSidebar,
  toggleMiniSidebar,
} from "../../redux/sidebarSlice";

import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { esES } from "@mui/x-date-pickers/locales";

const TemplateHeader = ({ schooldetails }) => {
  localStorage.setItem("imgUrl", schooldetails?.schoolLogo);
  const dispatch = useDispatch();
  const { authState, setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  const dataLayout = useSelector((state) => state.themeSetting.dataLayout);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [father, setfather] = useState("");
  const [token] = useState(localStorage.getItem("accessToken"));
  const academicYearIddata = localStorage.getItem("academicYearId");
  const [studentName, setStudentName] = useState(authState.name);
  const [schoolName, setSchoolName] = useState("");
  const [toast, addToast] = useState(0);
  const [allyear, setAllYear] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [startYear, setStartYear] = useState(academicYearIddata);
  const [totalvalue, setTotalValue] = useState("");
  // localStorage.setItem("localstartYear", startYear);
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    if (academicYearIddata !== startYear) {
      const updatedStartYear = academicYearIddata; // Replace with dynamic logic if neede
      setAuthState((prevState) => ({
        ...prevState,
        startYear: startYear, // Update authState context
      }));
    } else {
      setAuthState((prevState) => ({
        ...prevState,
        startYear: academicYearIddata, // Update authState context
      }));
    }
  }, [authState.startYear, startYear, setAuthState, currentPath]);

  useEffect(() => {
    console.log("startYear abhi kya hai", startYear);

    if (startYear !== localStorage.getItem("upcomingyear")) {
      setStartYear(localStorage.getItem("academicYearId"));
    }
  }, [currentPath]);
  console.log(schooldetails, "neqwwww", authState, startYear);

  // useEffect(() => {
  //   // Extract the school name and ID from the URL

  //   const currentPath = location.pathname;
  //   const pathParts = currentPath.split("/"); // Split the URL by '/'
  //   console.log("authState", currentPath);
  // }, [location.pathname]);

  const getModules = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/academics/academic-year`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAllYear(response?.data?.data);
      setTotalValue(response?.data?.results);
      // setDummyModules(response.data.data || []); // Check if data structure is correct
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching houses:", error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (authState.academiclenth !== totalvalue) {
      getModules();
    }
  }, [authState.academiclenth]);

  useEffect(() => {
    getModules();
  }, []);

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  const exampleErrorToast = (message, heading) => (
    <CToast>
      <CToastHeader closeButton>
        <svg
          className="rounded me-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill="#007aff"></rect>
        </svg>
        <div className="fw-bold me-auto">{heading}!</div>
      </CToastHeader>
      <CToastBody>{message}</CToastBody>
    </CToast>
  );

  const changepassword = () => {
    navigate("/school/changepassword");
  };

  const logout = async () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("currentURL");
    localStorage.removeItem("imgUrl");
    localStorage.removeItem("academicYearId");
    localStorage.removeItem("upcomingyear");

    navigate(
      `/school/admin/${localStorage.getItem(
        "schoolName"
      )}/${localStorage.getItem("uniqueId")}`
    );

    return;

    const responses = await axios
      .post(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params: {
          //   class: res.class,
          //   repetation: "weekly",
          //   applicableTo: res.gender,
          //   currentTime: new Date(),
          // },
        }
      )
      .then(function (response) {
        console.log(response.data.status);
        if (response.data.status == "success") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          // navigate("/");
          navigate(`/school/admin/${localStorage.getItem("schoolId")}`);
        }
      });
    // const response = await axios.post(
    //   `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/logout`,
    //   {
    //     headers: {
    //       // "Content-Type": "multipart/form-data", // Ensure Content-Type is set to multipart/form-data
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // ).then(function (response) {
    //   console.log(response);
    // //   localStorage.removeItem("accessToken");
    // // localStorage.removeItem("user");
    // // navigate("/");

    // });
  };

  const mobileSidebar = useSelector(
    (state) => state.sidebarSlice.mobileSidebar
  );

  // useEffect(() => {
  //   console.log(authState.id);
  //   const userInhead = async () => {
  //     try {
  //       const res = await axios.get(`${process.env.REACT_APP_DEV_BASE_URL}/api/v1/school/school-admin` ,{
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           },

  //         }

  //       );

  //       setStudentName(res.data?.data?.userData?.firstName + res.data?.data?.userData?.lastName);
  //       setSchoolName(res.data?.data?.school?.schoolName)
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     }
  //   };
  //   userInhead();
  // }, [authState.id, authState.name]);

  const toggleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };

  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  // const handleToggleMiniSidebar = () => {
  //   if (dataLayout === "mini_layout") {
  //     dispatch(setDataLayout("default_layout"));
  //     localStorage.setItem("dataLayout", "default_layout");
  //   } else {
  //     dispatch(toggleMiniSidebar());
  //   }
  // };

  const handleToggleMiniSidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const changeStudent = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_BASE_URL}/api/v1/users/siblings/sibling/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      console.log(response, "response from header");

      if (response?.data?.status === "success") {
        addToast(exampleErrorToast("siblings change successfully", "Great"));

        localStorage.setItem("userId", id);
        localStorage.setItem("accessToken", response.data?.token);

        window.location.reload();
      }
    } catch (error) {
      addToast(
        exampleErrorToast(
          error?.response?.data?.message || "An error occurred",
          "Oops"
        )
      ); // Show error toast
    }
  };

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div
          className="header-left active"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Link
            className="logo logo-normal d-none d-lg-flex "
            to="/school/dashboard"
          >
            <img
              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
              src={
                schooldetails?.schoolLogo
                  ? schooldetails?.schoolLogo
                  : "assets/img/logo.svg"
              }
              alt="Logo"
              className="school_logo"
            />
          </Link>
          <Link className="logo-small">
            <img
              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
              src={
                schooldetails?.schoolLogo
                  ? schooldetails?.schoolLogo
                  : "assets/img/logo.svg"
              }
              alt="Logo"
            />
          </Link>
          <Link className="dark-logo">
            <img
              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
              src={
                schooldetails?.schoolLogo
                  ? schooldetails?.schoolLogo
                  : "assets/img/logo.svg"
              }
              alt="Logo"
            />
          </Link>

          <Link id="toggle_btn" onClick={handleToggleMiniSidebar}>
            <i className="ti ti-menu-deep" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn  ff"
          onClick={handleToggleMiniSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        <div className="header-user">
          <div className="nav user-menu">
            {/* Search */}
            <div className="nav-item nav-search-inputs me-auto">
              <span class="p-0 d-flex align-items-center bg-white rounded p-2 text-dark fw-bold fs-4">
                Welcome to {schooldetails?.schoolName}
              </span>
            </div>

            {currentPath == "/school/addAadat" ||
            currentPath == "/school/editAadat" ||
            currentPath == "/school/create/category" ||
            currentPath == "/school/edit/category" ||
            currentPath == "/school/miqaat/create" ||
            currentPath == "/school/miqaat/edit" ||
            currentPath == "/school/add-working-days" ||
            currentPath == "/school/EditDay" ||
            currentPath == "/school/behaviour/point/add" ||
            currentPath == "/school/behaviour/Coupons/addCoupon" ||
            currentPath == "/school/behaviour/Coupons/editCoupon" ||
            currentPath == "/attendance/addattendance" ||
            currentPath == "/attendance/editattendance" ||
            currentPath == "/AddClassAttend" ||
            currentPath == "/EditClassAttend" ||
            currentPath == "/school/points/assign" ||
            currentPath == "/school/addStudent" ||
            currentPath == "/school/editStudent" ||
            currentPath == "/school/editStudent" ||
            currentPath == "/school/addterm" ||
            currentPath == "/school/editterm" ? (
              <> </>
            ) : (
              <>
                {" "}
                <div className="seleted_year">
                  <label className="form-label">Academic Year</label>{" "}
                  {console.log("startYear11111111111111111", startYear)}
                  <select
                    name=""
                    id=""
                    style={{ width: "150px" }}
                    className="form-select pt-0"
                    value={startYear}
                    onChange={(e) => {
                      setStartYear(e.target.value);
                    }}
                  >
                    <option value="" disabled selected>
                      {" "}
                      Select year{" "}
                    </option>
                    {(currentPath === "/school/manageStudent" ||
                      currentPath === "/school/term" ||
                      currentPath === "/school/points") && (
                      <option value="all">All</option>
                    )}

                    {allyear?.map((res, i) => (
                      <option value={res?._id}>
                        {" "}
                        {new Date(res?.start_year).getFullYear()}-
                        {new Date(res?.end_year).getFullYear()}{" "}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            {/* /Search */}
            <div className="d-flex align-items-center">
              <div className="dropdown-menu dropdown-menu-end notification-dropdown p-4">
                <div className="noti-content">
                  <div className="d-flex flex-column">
                    <div className="border-bottom mb-3 pb-3">
                      <Link>
                        <div className="d-flex">
                          <span className="avatar avatar-lg me-2 flex-shrink-0">
                            <img
                              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
                              src="assets/img/profiles/avatar-27.jpg"
                              alt="Profile"
                            />
                          </span>
                          <div className="flex-grow-1">
                            <p className="mb-1">
                              <span className="text-dark fw-semibold">
                                Shawn
                              </span>{" "}
                              performance in Math is below the threshold.
                            </p>
                            <span>Just Now</span>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="border-0 mb-3 pb-0">
                      <Link>
                        <div className="d-flex">
                          <span className="avatar avatar-lg me-2 flex-shrink-0">
                            <img
                              crossorigin={process.env.REACT_APP_DEV_BASE_URL}
                              src="assets/img/profiles/avatar-01.jpg"
                              alt="Profile"
                            />
                          </span>
                          <div className="flex-grow-1">
                            <p className="mb-1">
                              A new teacher record for{" "}
                              <span className="text-dark fw-semibold">
                                Elisa
                              </span>
                            </p>
                            <span>09:45 AM</span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="d-flex p-0">
                  <Link to="#" className="btn btn-light w-100 me-2">
                    Cancel
                  </Link>
                  <Link className="btn btn-primary w-100">View All</Link>
                </div>
              </div>

              <div className="dropdown ms-1 d-flex align-items-center">
                <h6 class="text-gray-800 pright-3">
                  School Admin{" "}
                  <span className="text-info">{schooldetails?.fullName}</span>
                </h6>
                <Link
                  to="#"
                  className="dropdown-toggle d-flex flex-column align-items-center"
                  data-bs-toggle="dropdown"
                >
                  <span className="avatar avatar-md">
                    <img
                      crossorigin={process.env.REACT_APP_DEV_BASE_URL}
                      src={
                        schooldetails?.schoolLogo
                          ? schooldetails?.schoolLogo
                          : user1
                      }
                      alt="Img"
                      className="img-fluid rounded"
                    />
                  </span>
                </Link>

                <div className="dropdown-menu">
                  <div className="d-block">
                    <div className="d-flex align-items-center p-2">
                      <span className="avatar avatar-md me-2 online avatar-rounded">
                        <img
                          crossorigin={process.env.REACT_APP_DEV_BASE_URL}
                          src={
                            schooldetails?.schoolLogo
                              ? schooldetails?.schoolLogo
                              : user1
                          }
                          alt="img"
                          className="img-fluid rounded-circle mright-20px"
                        />
                      </span>
                      <p className="mt-2 fw-bold">{schooldetails?.fullName}</p>
                      <div></div>
                    </div>
                    <hr className="m-0" />
                    <button
                      className="dropdown-item d-inline-flex align-items-center p-2"
                      onClick={changepassword}
                    >
                      <i className="ti ti-user-circle me-2" />
                      Change Password
                    </button>

                    <hr className="m-0" />
                    <button
                      className="dropdown-item d-inline-flex align-items-center p-2"
                      onClick={logout}
                    >
                      <i className="ti ti-login me-2" />
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateHeader;
